import React from "react";

export const TermsAndConditions = () => {
    return <div>
        <h2>General</h2>
        <ul>
            <li>These Terms and Conditions relate to the website Fairy World Quest at www.fairyworldquest.com and the
                Fairy World Quest App, operated by Like A Photon Creative Pty Ltd (ACN: 159 756 279).
            </li>
            <li>A reference to we, our or us is a reference to Like A Photon, and a reference to Fairy World Quest is a
                reference to the website or the app, whichever you are using.
            </li>
        </ul>


        <h2>Age</h2>
        <ul>
            <li>Fairy World Quest is aimed at preschool and primary school aged children (Child Users) who are using
                Fairy World Quest under active supervision by their parent or guardian (Parent Users).
            </li>
            <li>In these Terms and Conditions, a reference to “You” and “User” is a reference both to the Child User and
                to the Parent User both in their personal capacity and for and on behalf of the Child User.
            </li>
        </ul>


        <h2>Intellectual Property</h2>
        <ul>
            <li>All of our information, text, material, images, audio, video, graphics,software and our advertisements
                on Fairy World Quest (our Content) are copyright © 2017-{(new Date()).getFullYear()} Like A Photon
                Creative Pty Ltd, our associated companies, suppliers, and/or licensors unless expressly indicated
                otherwise. our Content is protected by Australian and international copyright laws.
            </li>
            <li>You must not download, remove, modify, copy, reproduce, republish, frame, upload to a third party, post,
                transmit, distribute or in any way deal with our Content except as expressly provided in these Terms and
                Conditions,on Fairy World Quest, or expressly authorised by us in writing.
            </li>
            <li>You must not use our trademarks, logos or other materials for any purpose without our prior written
                consent.
            </li>
        </ul>


        <h2>Use</h2>
        <ul>
            <li>You agree to use Fairy World Quest, including all features and functionalities, in accordance with all
                applicable laws, rules and regulations, or other restrictions on use of the service or content contained
                in Fairy World Quest. You agree not to archive, reproduce, distribute, modify, display, perform,
                publish, licence, create derivative works from, offer for sale, or use (except as explicitly set out in
                these Terms and Conditions) our Content.
            </li>
            <li>You also agree not to, and not to seek to: (a) circumvent, remove, alter, deactivate, degrade or inhibit
                any of the content protections in Fairy World Quest; (b) use any robot, spider, scraper or other
                automated means to access the Fairy World Quest service; (c) insert any code or product or manipulate
                the content of Fairy World Quest in any way; or (d) use any data mining, data gathering or extraction
                method on or in relation to Fairy World Quest.
            </li>
            <li>You agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt,
                destroy or limit the functionality of any computer software or hardware or telecommunications equipment
                associated with Fairy World Quest in any form, including any software viruses or any other computer
                code, files or programs.
            </li>
            <li>We may terminate or restrict your use of Fairy World Quest at any time if you violate these Terms and
                Conditions or are engaged in illegal or fraudulent use of Fairy World Quest.
            </li>
        </ul>


        <h2>Links</h2>
        <ul>
            <li>Access to any third-party site which may be reached by the hyperlinks on this Site (Linked Site) is
                subject to any notices, including but not limited to copyright notices, which may appear throughout the
                Linked Site.
            </li>
            <li>Accessing a Linked Site from this Site, regardless of whether the link was posted by us or by other
                users, does not expressly or impliedly constitute any guarantee, undertaking or warranty on our part as
                to the accuracy, completeness, copyright status or up-to-date nature of the information contained on the
                Linked Site. We will not be liable to you or any third party for losses, costs, damaged or other
                expenses incurred as a result of such access and the use of any information contained on a Linked Site.
            </li>
            <li>The ability to access a Linked Site does not:</li>
            <li>constitute express of implied authority to infringe copyright in any content contained on the Linked
                Site; or
            </li>
            <li>imply any connection, sponsorship or affiliation between the Linked Site and this Site or our Company.
            </li>
        </ul>


        <h2>Privacy</h2>
        <ul>
            <li>You accept our Privacy Policy, found here <a
                href={"/privacy-policy"}>https://www.fairyworldquest.com/privacy-policy</a>, and we agree that all your
                personal information (as defined in the Privacy Policy) will be dealt with by us only in accordance with
                our Privacy Policy.
            </li>
        </ul>


        <h2>Disclaimers and Limitation of Liability</h2>
        <ul>
            <li>To the fullest extent permitted by law:</li>
            <li>all information and materials on Fairy World Quest is provided “as is” and without warranty of any kind,
                express or implied;
            </li>
            <li>all implied warranties as to merchantability and fitness for a particular use or purpose are excluded;
            </li>
            <li>we and our information providers make no warranty as to the reliability, accuracy, timeliness,
                usefulness or completeness of any information or materials on Fairy World Quest;
            </li>
            <li>we make no warranties in respect of human or machine errors, omissions, delays, interruptions, or losses
                including but not limited to loss of data; and
            </li>
            <li>we make no warranties that files and/or data available for downloading from Fairy World Quest, or the
                server transmitting information and materials to you, will be free of infection, viruses or other code
                that manifest contaminating or destructive properties.
            </li>
            <li>Further, our liability for the breach of any warranty or condition which cannot by law be excluded
                shall, at our option, be limited to the following:In the case of services supplied by us:
            </li>
            <li>the supply of the services again; or</li>
            <li>the payment of the cost of having the services supplied again; and</li>
            <li>In the case of goods supplied by us:</li>
            <li>the replacement of the goods or the supply of equivalent goods;</li>
            <li>the payment of the cost of replacing the goods or the supply of equivalent goods; or</li>
            <li>the payment of the cost of having the goods repaired.</li>
            <li>Under no circumstances (including but not limited to any act or omission on our part, our servants or
                agents) will we or our associated companies be liable for any indirect, incidental, special or
                consequential damages or loss of profits whatsoever which result from any use of or access to, or
                inability to use or access Fairy World Quest or any content or other materials contained on Fairy World
                Quest.
            </li>
        </ul>


        <h2>Indemnity</h2>
        <ul>
            <li>You agree to indemnify us and keep us, our officers, directors, employees, servants, agents, licensors,
                licensees and suppliers, indemnified from and against all losses, expenses, damages and costs, including
                reasonable legal fees, resulting from any breach of these Terms and Conditions or any activity related
                to your registered account (including negligent or wrongful conduct) by you or any other person using
                your registered account.
            </li>
        </ul>


        <h2>Variation and Termination</h2>
        <ul>
            <li>These Terms and Conditions shall continue to have full force and effect until varied or terminated by
                us. The Terms and Conditions relating to intellectual property, your licenses to us (if any), the
                indemnity granted by you, and all disclaimers and limitations of liability shall survive the termination
                of this agreement.
            </li>
            <li>We reserve the right to vary these Terms and Conditions at any time, including by posting new or varied
                Terms and Conditions on Fairy World Quest. You will be given the opportunity to accept such varied Terms
                and Conditions on your first visit to Fairy World Quest after such variation occurs, and if you elect
                not to accept the Terms and Conditions as varied your permission to use Fairy World Quest will terminate
                immediately.
            </li>
        </ul>


        <h2>General</h2>
        <ul>
            <li>This agreement is governed by the laws of the State of Queensland, Australia. You irrevocably submit to
                the jurisdiction of the courts of that State.
            </li>
            <li>If any provision of this agreement is found to be invalid or unenforceable by a court of law, such
                invalidity or unenforceability shall not affect the remainder of the agreement which shall continue to
                have full force and effect.
            </li>
            <li>our waiver of a breach by you of these Terms and Conditions does not amount to a waiver of all breaches
                by you, and we reserve our rights pursuant to this agreement in respect of any other or further breaches
                by you of these Terms and Conditions.
            </li>
        </ul>
        <p>These Terms and Conditions were last updated December 2018.</p>
    </div>;
};
