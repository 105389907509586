import React from "react";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { TermsAndConditions } from "../components/TermsAndConditions";

const Page = () => {
    return (
        <>
            <SEO title={"Terms and Conditions"} lang={"en"} meta={[]} />
            <Layout label={"Fairy World Quest - Terms and Conditions"}>
                <TermsAndConditions />
            </Layout>
        </>
    );
};


export default Page;
